import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import SeeMore from "../components/SeeMore";
import HeaderInsegne from "../components/HeaderInsegne";
import InsegnaNumber from "../components/InsegnaNumber";
import CardCareer from "../components/Cards/CardCareer";
import Title from "../components/Title";

import logo from "../static/logos/logo-city.png";

import puntiVendita from "../images/numbers/numbers-1-city.png";
import headerBg from "../images/insegne/pam-city.jpg";

import "../utils/page-insegne.scss";
import "../utils/general.scss";

const PamCity = ({ pageContext }) => {
    return (
        <Layout pageContext={pageContext}>
            <SEO title={`Pam City | ${pageContext.localeResources['menu.insegne']}`}>
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
            </SEO>

            <HeaderInsegne 
                title={pageContext.localeResources['insegne.pamCity.title']}
                text={pageContext.localeResources['insegne.pamCity.text']}
                logo={logo}
                link="https://www.pampanorama.it/"
                linkText={pageContext.localeResources['insegne.pamCity.linkText']}
                headerImg={headerBg}
            />

            <section className="section-number-single colorful-bg">
                <div className="container section">
                    <Title title={`${pageContext.localeResources['insegne.numbers']} Pam City`} />
                    <InsegnaNumber
                        image={puntiVendita}
                        number="2"
                        text={pageContext.localeResources['insegne.stores']}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default PamCity
